// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("turbolinks").start()

// require('@rails/activestorage').start();
// require('channels');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Initially require by brand_center

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'event-target-polyfill';

import 'polyfills';

import ReactOnRails from 'react-on-rails';

import { ProductReviewLanding } from '../components/Bazaarvoice/ProductReviewLanding';
import FeatureFlagAlert from '../components/FeatureFlag/FeatureFlagAlert';
import HVACSplash from '../components/HVACSplash';
import IFramedForm from '../components/IFramedForm';
import FindAProfessional from '../components/Insulation/FindAProfessional';
import AttentionBar from '../components/OC/PageBuilder/AttentionBarPicker';
import Reusable from '../components/OC/PageBuilder/Reusable';
import Footer from '../components/OC/footer';
import AlertMessage from '../components/OC/oc-alert-message';
import AttentionBarWithTimer from '../components/OC/oc-attention-bar-with-timer';
import FlexBanner from '../components/OC/oc-flex-banner';
import Hero from '../components/OC/oc-hero';
import VideoWrapper from '../components/OC/oc-video-wrapper';
import RichText from '../components/OC/oc-rich-text';
import Text from '../components/OC/oc-text';
import QuotingWidgetConfigurator from '../components/Roofing/QuotingWidget/Configurator';
import HomeownerInfo from '../components/Roofing/QuotingWidget/HomeownerInfo';
import ShingleWidget from '../components/ShingleWidget/Index';
import ToggleFooter from '../components/ToggleFooter';
import AssetDownloadsIndex from '../components/brand_center/Asset/Downloads/Index';
import AssetDownloadsThumbnail from '../components/brand_center/Asset/Downloads/Thumbnail';
import TipsAndTricks from '../components/brand_center/Modals/TipsAndTricks';
import SearchResultsIndex from '../components/brand_center/Search/Results/Index';
import PinkBarVsSteelCalculator from '../components/composites/PinkBarVsSteel/PinkBarVsSteelCalculator';
import CrossingLocale from '../components/location/CrossingLocale';
import Switcher from '../components/location/Switcher';

require('@rails/ujs').start();
require('@rails/activestorage').start();

// This is how react_on_rails can see the react_component('ComponentName') in the browser.
// Use :: in place of / and capitalize folder/component names
ReactOnRails.register({
  'OC::Hero': Hero,
  'FeatureFlag::Featureflagalert': FeatureFlagAlert,
  'OC::Oc-alert-message': AlertMessage,
  'OC::Oc-flex-banner': FlexBanner,
  'OC::Oc-attention-bar-with-timer': AttentionBarWithTimer,
  HVACSplash,
  IFramedForm,
  'OC::Oc-video-wrapper': VideoWrapper,
  'OC::Richtext': RichText,
  'OC::Text': Text,
  'Location::Switcher': Switcher,
  'Location::Crossinglocale': CrossingLocale,
  'ShingleWidget::Index': ShingleWidget,
  'Roofing::Quotingwidget::Customize': QuotingWidgetConfigurator,
  'Roofing::Quotingwidget::Homeownerinfo': HomeownerInfo,
  'Composites::Pinkbarvssteel::Pinkbarvssteelcalculator': PinkBarVsSteelCalculator,
  ToggleFooter,
  'OC::Footer': Footer,

  AttentionBar,
  'Builder::Reusable': Reusable,

  'BrandCenter::Modals::Tipsandtricks': TipsAndTricks,
  'BrandCenter::Search::Results::Index': SearchResultsIndex,
  'BrandCenter::Asset::Downloads::Index': AssetDownloadsIndex,
  'BrandCenter::Asset::Downloads::Thumbnail': AssetDownloadsThumbnail,
  'Insulation::FindAProfessional::Index': FindAProfessional,
  'Bazaarvoice::ProductReviewLanding': ProductReviewLanding,
});
